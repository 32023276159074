import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import StateContext from "../../../context/StateContext";
import Navbar from '../Navbar';
import { ReportFilter } from './ReportFilter';
import ReportTable from './ReportTable';
// import { BaseAlert } from './Alert';

export const Report = (props) => {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const { appState, setappState } = state;
  let newDate = new Date()
  let date = newDate.getDate() < 10 ? `0${(newDate.getDate())}` : `${(newDate.getDate())}`;
  let mnth = (newDate.getMonth() + 1) < 10 ? `0${(newDate.getMonth() + 1)}` : `${(newDate.getMonth() + 1)}`;
  let year = newDate.getFullYear();
  let fulldate = `${year}-${mnth}-${date}`;

  const [fdate, setfdate] = useState(fulldate);
  const [tdate, settdate] = useState(fulldate);
  const [updateFilter, setupdateFilter] = useState(false)
  const [type, settype] = useState("")
  const [saleData, setsaleData] = useState({
    success: "",
    successam: "",
    pending: "",
    pendingam: "",
    failed: "",
    failedam: "",
  })
  useEffect(() => {
    if (localStorage.getItem("token") === "" || localStorage.getItem("token") === null) {
      navigate("/");
    }
    else {
      setappState({
        "login": true,
        "authToken": localStorage.getItem("token")
      })
    }
  }, []);

  const logout = () => {
    localStorage.setItem("token", "");
    navigate("/");
  }

  return (<>
    <Navbar logout={logout} />
    {/* <BaseAlert type="success" msg="Hello"/> */}
    {/* <RechForm/> */}
    <div className="container ">
      <div className="row  mt-3 justify-content-center ">
        <div className="col-3">
          <div className="card p-3">
            <h4>Success</h4>
            <h6 className='m-0 '>Number : <span>{saleData.success}</span></h6>
            <h6 className='m-0 '>Amount : <span>{saleData.successam}</span></h6>
          </div>
        </div>
        <div className="col-3">
          <div className="card p-3 warning-border">
            <h4>Pending</h4>
            <h6 className='m-0 '>Number : <span>{saleData.pending}</span></h6>
            <h6 className='m-0 '>Amount : <span>{saleData.pendingam}</span></h6>
          </div>
        </div>
        <div className="col-3">
          <div className="card p-3">
            <h4>Failed</h4>
            <h6 className='m-0 '>Number : <span>{saleData.failed}</span></h6>
            <h6 className='m-0 '>Amount : <span>{saleData.failedam}</span></h6>
          </div>
        </div>
      </div>
    <ReportFilter fdate={fdate} type={type} settype={settype} tdate={tdate} updateFilter={updateFilter} setupdateFilter={setupdateFilter} setfdate={setfdate} settdate={settdate} />
    </div>
    <ReportTable fdate={fdate} tdate={tdate} saleData={saleData} setsaleData={setsaleData} type={type} updateFilter={updateFilter} setupdateFilter={setupdateFilter} />
  </>);
};
