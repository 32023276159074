import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import Chip from "@mui/material/Chip";

const AdminToken = localStorage.getItem("AdminToken");

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "ID",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "Multi Name",
    numeric: false,
    disablePadding: false,
    label: "Multi Name",
  },
  {
    id: "Name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },

  {
    id: "Operator",
    numeric: false,
    disablePadding: false,
    label: "Operator",
  },
  {
    id: "Number",
    numeric: true,
    disablePadding: false,
    label: "Number",
  },
  {
    id: "Amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },

  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },

  {
    id: "Order ID",
    numeric: false,
    disablePadding: false,
    label: "Order ID",
  },

  {
    id: "OPid",
    numeric: false,
    disablePadding: false,
    label: "OPid",
  },

  {
    id: "Service",
    numeric: false,
    disablePadding: false,
    label: "Service",
  },

  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },

  {
    id: "RT Time",
    numeric: false,
    disablePadding: false,
    label: "RT Time",
  },

  {
    id: "Timestamp",
    numeric: false,
    disablePadding: false,
    label: "Timestamp",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "Select All",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, selected, setloading, refresh } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          All Reports
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          <Tooltip
            title="Refund"
            onClick={() =>
              AllinOneInaction(selected, "Refund", setloading, refresh)
            }
          >
            <Chip label="Refund" color="primary" />
          </Tooltip>
          <Tooltip
            title="Failed"
            onClick={() =>
              AllinOneInaction(selected, "Failed", setloading, refresh)
            }
          >
            <Chip label="Failed" color="secondary" />
          </Tooltip>
          <Tooltip
            title="Success"
            onClick={() =>
              AllinOneInaction(selected, "Success", setloading, refresh)
            }
          >
            <Chip label="Success" color="primary" />
          </Tooltip>
          {/* <div className="row">
                        <div className="col-3 mx-1">
                            <button className='btn btn-primary'>Inactive</button>
                        </div>
                        <div className="col-3 mx-1">
                            <Chip label="Inacitve" color="primary" />
                            <button className='btn btn-primary'>Avail Off</button>
                        </div>
                        <div className="col-3 mx-1">
                            <Chip label="Inacitve" color="primary" />
                            <button className='btn btn-primary'>Active</button>
                        </div>
                    </div> */}
        </>
      ) : (
        <></>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const AllinOneInaction = async (selected, action, setloading, refresh) => {
  if (window.confirm("Are you sure ? ") === true) {
    setloading(true);
    const json = JSON.stringify({ AdminToken, selected, action });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/admin/recharge/Allinoneaction",
      requestOptions
    );
    const rspns = await req.json();
    console.log(rspns);
    if (rspns.Status) {
      alert(rspns.Msg);
      refresh(true);
      setloading(false);
    } else {
      alert(rspns.Msg);
      setloading(false);
    }
  }
};
function getUnique(arr, index) {
  const unique = arr
    .map((e) => e[index])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
}

export default function AllReportTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const { data, setloading, refresh } = props;
  const rows = getUnique(data, "ID");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.ID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, ID) => {
    const selectedIndex = selected.indexOf(ID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, ID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%", marginTop: "30px" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          refresh={refresh}
          setloading={setloading}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.ID);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let stcolor = "badge badge-warning";
                  if (row.STATUS === "PENDING") {
                    stcolor = "badge badge-warning";
                  } else if (row.STATUS === "SUCCESS") {
                    stcolor = "badge badge-success";
                  } else if (row.STATUS === "REFUNDED") {
                    stcolor = "badge badge-info";
                  } else {
                    stcolor = "badge badge-danger";
                  }
                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClick(event, row.ID)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.ID}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.ID}
                      </StyledTableCell>
                      {/* <StyledTableCell align="left"><Avatar {...stringAvatar(`${row.FNAME}`)} /></StyledTableCell> */}

                      <StyledTableCell align="left">
                        {row.MULTINAME}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.RTNAME}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.OPERATOR}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.NUMBER}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.AMOUNT}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <span className={stcolor}>{row.STATUS}</span>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.ORDERID}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.OPID}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.SERVICE}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.DATE}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.RTTIME}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.TIMESTAMP}
                      </StyledTableCell>
                      {/* <StyledTableCell align="left">{row.Action}</StyledTableCell> */}
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 250, 500]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
