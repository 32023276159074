import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

export const ReportFilter = (props) => {

    const Token = localStorage.getItem("token");
    const [loading, setloading] = useState(false);
    const { fdate, setfdate, tdate, settdate, setupdateFilter, updateFilter , type , settype } = props;
    const [filterapply, setfilterapply] = useState(true)
    const setDefaultfilter = () => {
        let newDate = new Date()
        let date = newDate.getDate() < 10 ? `0${(newDate.getDate())}` : `${(newDate.getDate())}`;
        let mnth = (newDate.getMonth() + 1) < 10 ? `0${(newDate.getMonth() + 1)}` : `${(newDate.getMonth() + 1)}`;
        let year = newDate.getFullYear();
        let fulldate = `${year}-${mnth}-${date}`;
        setfdate(fulldate);
        settdate(fulldate);
        setupdateFilter(true);
        setfilterapply(true);
    }
    const submitfilter = () => {
        setupdateFilter(true);
        setfilterapply(false);
    }
    return (
        <>
            {loading ? <LinearProgress /> : ""}
            <Container maxWidth="lg" style={{ marginTop: "20px" }}>
                <h3>Filter</h3>
                <Grid container spacing={1}>
                    <Grid item xs={3} md={3} lg={3}>
                        <label htmlFor="">From Date</label> <br />
                        <input type="date" value={fdate} onChange={(event) => { setfdate(event.target.value); }} />
                    </Grid>
                    <Grid item xs={3} md={3} lg={3}>
                        <label htmlFor="">To Date</label> <br />
                        <input type="date" value={tdate} onChange={(event) => { settdate(event.target.value); }} />
                    </Grid>
                    <Grid item xs={3} md={3} lg={3}>
                        <label htmlFor="">Type</label> <br />
                        <select name="type" onChange={(e) => settype(e.target.value) } className="form-control stock">
                            <option value="" selected={type === ""} >Type </option>
                            <option value="success" selected={type === "success"} >success</option>
                            <option value="pending" selected={type === "pending"} >pending</option>
                            <option value="failed" selected={type === "failed"} >failed</option>
                            <option value="refunded" selected={type === "refunded"} >refunded</option>
                        </select>
                    </Grid>
                </Grid>
                <Button variant="contained" style={{ margin: '30px' }} disabled={loading} onClick={submitfilter} >Update Filter</Button>
                <Button variant="outlined" style={{ margin: '30px' }} disabled={filterapply} onClick={setDefaultfilter}>Clear Filter</Button>
            </Container>
        </>
    )
}

