import React from "react";
import MuiCard from "../components/MuiCard";

import { useEffect, useState } from "react";

import TodayReportTable from "./components/TodayReportTable";
import { Loading } from "./components/Loading";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { alpha, styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: red[600],
    "&:hover": {
      backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: red[600],
  },
}));

export const Dashboard = (props) => {
  const AdminToken = localStorage.getItem("AdminToken");
  const [page, setpage] = useState(0);
  const [rows, setrows] = useState([]);
  const [loading, setloading] = useState(true);
  const [refresh, setrefresh] = useState(false);
  const [details, setdetails] = useState({
    success: 0,
    pending: 0,
    failed: 0,
    refunded: 0,
    refundedam: 0,
    successam: 0,
    failedam: 0,
    pendingam: 0,
  });
  const [rechRule, setrechRule] = useState({
    amount: "",
    status: "OFF",
    alloff: "OFF",

    AdminToken: AdminToken,
  });
  useEffect(() => {
    getData();
    getrechRule();
  }, []);

  const getData = async () => {
    // setpage(page + 1);
    setloading(true);
    const json = JSON.stringify({ AdminToken, page });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/admin/recharge/getHomeData",
      requestOptions
    );
    const rspns = await req.json();
    console.log(rspns);
    if (rspns.Status) {
      if (rspns.Result !== null) {
        setdetails({
          success: Number(rspns.success),
          pending: Number(rspns.pending),
          failed: Number(rspns.failed),
          refunded: Number(rspns.refunded),
          refundedam: Number(rspns.refundedam),
          successam: Number(rspns.successam),
          failedam: Number(rspns.failedam),
          pendingam: Number(rspns.pendingam),
        });
        setrows(rspns.Result);
        // console.log(total)
        setloading(false);
      }
    } else {
      setloading(false);
      setrows([]);
      setdetails({
        success: 0,
        pending: 0,
        failed: 0,
        refunded: 0,
        refundedam: 0,
        successam: 0,
        failedam: 0,
        pendingam: 0,
      });
    }
  };
  const getrechRule = async () => {
    setloading(true);
    const json = JSON.stringify({ rechRule, update: false });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/admin/recharge/rechRule",
      requestOptions
    );
    const rspns = await req.json();
    console.log(rspns);
    if (rspns.Status) {
      if (rspns.Result !== null) {
        setloading(false);
        setrechRule({
          amount: rspns.Result[0].amount,
          status: rspns.Result[0].status,
          alloff: rspns.Result[0].alloff,

          AdminToken: AdminToken,
        });
      }
    } else {
      setloading(false);
      alert(rspns.Msg);
    }
  };

  const handleChange = (e) => {
    setrechRule({
      ...rechRule,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handlestChange = () => {
    setrechRule({
      amount: rechRule.amount,
      status: rechRule.status === "OFF" ? "ON" : "OFF",
      alloff: rechRule.alloff,
      AdminToken: AdminToken,
    });
  };

  const handlealstChange = () => {
    setrechRule({
      amount: rechRule.amount,
      status: rechRule.status,
      alloff: rechRule.alloff === "OFF" ? "ON" : "OFF",
      AdminToken: AdminToken,
    });
  };

  const updateRule = async () => {
    setloading(true);
    const json = JSON.stringify({ rechRule, update: true });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/admin/recharge/rechRule",
      requestOptions
    );
    const rspns = await req.json();
    console.log(rspns);
    setloading(false);
    if (rspns.Status) {
      alert(rspns.Msg);
      getrechRule();
    } else {
      alert(rspns.Msg);
    }
  };

  if (refresh) {
    setloading(true);
    setpage(0);
    setrows([]);
    setdetails({
      success: 0,
      pending: 0,
      failed: 0,
      refunded: 0,
      refundedam: 0,
      successam: 0,
      failedam: 0,
      pendingam: 0,
    });
    getData();
    setrefresh(false);
  }
  // console.log(rechRule.status === "ON")
  return (
    <>
      <Loading loading={loading} />
      <div className="container">
        <div className="row">
          <div className="col-6">
            <label htmlFor="amount">Enter Amount (10,49,79)</label>
            <input
              type="text"
              className="form-control mb-2"
              placeholder="Rech Off Amount"
              name="amount"
              value={rechRule.amount}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 row">
            <div className="col-4">
              <label htmlFor="amountwise">Amount Wise Off</label>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={rechRule.status === "ON"}
                      onChange={handlestChange}
                    />
                  }
                  label={rechRule.status}
                />
              </FormGroup>
            </div>
            <div className="col-4">
              <label htmlFor="alloff">All Off</label>
              <FormGroup>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={rechRule.alloff === "ON"}
                      onChange={handlealstChange}
                    />
                  }
                  label={rechRule.alloff}
                />
              </FormGroup>
            </div>
            <div className="col-3">
              <button className="btn btn-primary m-2 ml-2" onClick={updateRule}>
                Update Rule
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 my-1">
            <MuiCard maintext="Success" text={details.success} icon="bx:user" />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard maintext="Pending" text={details.pending} icon="bx:user" />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="Failed"
              text={details.failed}
              icon="bx:user-check"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="Refunded"
              text={details.refunded}
              icon="bx:user-check"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="S. Amount"
              text={details.successam}
              icon="bx:user"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="P. Amount"
              text={details.pendingam}
              icon="bx:user-check"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="F. Amount"
              text={details.failedam}
              icon="bx:user"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="R. Amount"
              text={details.refundedam}
              icon="bx:user"
            />
          </div>
        </div>

        <TodayReportTable
          data={rows}
          setloading={setloading}
          refresh={setrefresh}
        />
      </div>
    </>
  );
};
