import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useEffect } from "react";

import LinearProgress from "@mui/material/LinearProgress";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const RechForm = () => {
  const Token = localStorage.getItem("token");
  const [loading, setloading] = useState(false);
  const [Operators, setOperators] = useState([]);
  const [allService, setallService] = useState([]);
  const [amount, setamount] = useState("");
  const [mamount, setmamount] = useState("");
  const changeamount = (event) => {
    setamount(event.target.value);
  };
  const changemamount = (event) => {
    setmamount(event.target.value);
  };
  const [Service, setService] = React.useState("Select");
  const handleChange = (event) => {
    setService(event.target.value);
    getServiceOperator(event.target.value);
  };
  const [selectedOperator, setselectedOperator] = React.useState([]);

  const handleselectChange = (event) => {
    const {
      target: { value },
    } = event;
    setselectedOperator(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (Token !== "" && Token !== null) {
      getService();
      getData();
    }
  }, []);

  const getData = async () => {
    setloading(true);
    const json = JSON.stringify({ Token });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/retailer/getFilter",
      requestOptions
    );
    const rspns = await req.json();
    //   console.log(rspns);
    if (rspns.Status) {
      setloading(false);
      setamount(rspns.Amount);
      setmamount(rspns.M_Amount);
      if (rspns.Operators !== null) {
        setOperators(rspns.Operators);
        setselectedOperator(rspns.Operators);
      }
      setService(rspns.Service);
    } else {
      setloading(false);
      console.log(rspns.Msg);
    }
  };
  const getService = async () => {
    setloading(true);
    const json = JSON.stringify({ getServices: "getServices" });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/retailer/getFilter",
      requestOptions
    );
    const rspns = await req.json();
    //   console.log(rspns);
    if (rspns.Status) {
      setloading(false);
      if (rspns.Services !== "" && rspns.Services !== null) {
        setallService(rspns.Services);
      }
    } else {
      setloading(false);
      alert(rspns.Msg);
    }
  };
  const getServiceOperator = async (service) => {
    // console.log(service);

    setloading(true);
    const json = JSON.stringify({ getServiceOperators: service });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/retailer/getFilter",
      requestOptions
    );
    const rspns = await req.json();
    //   console.log(rspns);
    if (rspns.Status) {
      setloading(false);
      if (rspns.Operator !== "" && rspns.Operator !== null) {
        setOperators(rspns.Operator);
        setselectedOperator([]);
      }
    } else {
      setloading(false);
      alert(rspns.Msg);
    }
  };

  const updateFilter = async () => {
    setloading(true);
    const json = JSON.stringify({
      Service,
      amount,
      mamount,
      selectedOperator,
      Token,
    });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/retailer/updateFilter",
      requestOptions
    );
    const rspns = await req.json();
    console.log(rspns);
    if (rspns.Status) {
      setloading(false);
      alert(rspns.Msg);
    } else {
      setloading(false);
      alert(rspns.Msg);
    }
  };
  return (
    <>
      <React.Fragment>
        {loading ? <LinearProgress /> : ""}
        <CssBaseline />
        <Container maxWidth="lg" style={{ marginTop: "20px" }}>
          <h3>Recharge Rules</h3>
          <Grid container spacing={1}>
            <Grid item xs={3} md={3} lg={3}>
              <TextField
                id="amount"
                label="Min Amount"
                value={amount}
                onChange={changeamount}
                helperText=""
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <TextField
                id="mamount"
                label="Max Amount"
                value={mamount}
                onChange={changemamount}
                helperText=""
              />
            </Grid>
            <Grid item xs={4} md={3} lg={3}>
              <FormControl
                required
                sx={{ m: 0, minWidth: 100 }}
                md={{ m: 0, width: 300 }}
              >
                <InputLabel id="service">Service</InputLabel>
                <Select
                  labelId="service"
                  id="service"
                  value={Service}
                  label="Service"
                  onChange={handleChange}
                >
                  <MenuItem value="Select">
                    <em>Select</em>
                  </MenuItem>
                  {allService.map((name) => (
                    <MenuItem key={name.SERVICENAME} value={name.SERVICENAME}>
                      {name.SERVICENAME}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={""}>Prepaid</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} md={3} lg={3}>
              <FormControl sx={{ m: 0, width: 100 }} md={{ m: 0, width: 300 }}>
                <InputLabel id="Operator">Operator</InputLabel>
                <Select
                  labelId="Operator"
                  id="Operator"
                  multiple
                  value={selectedOperator}
                  onChange={handleselectChange}
                  input={<OutlinedInput label="Operator" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {Operators.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={selectedOperator.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>{" "}
          <Button
            variant="contained"
            style={{ marginTop: "10px" }}
            disabled={loading}
            onClick={updateFilter}
          >
            Update
          </Button>
        </Container>
      </React.Fragment>
    </>
  );
};

export default RechForm;
