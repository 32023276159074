import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

const AdminToken = localStorage.getItem("AdminToken");
export const Setcom = () => {
  const { id } = useParams();
  const [formData, updateFormData] = React.useState({
    amount: "",
    comm: "",
    packid: id,
    AdminToken: AdminToken,
  });
  useEffect(() => {
    getData();
  }, []);
  console.log(id);
  const [packlist, setpacklist] = useState([]);
  const [loading, setloading] = useState(false);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  const getData = async () => {
    setloading(true);
    const json = JSON.stringify(formData);
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/admin/commission/fetch_com",
      requestOptions
    );
    const rspns = await req.json();

    setloading(false);
    if (rspns.Status) {
      if (rspns.Result != null) {
        setpacklist(rspns.Result);
      }
    } else {
      setpacklist([]);
      alert(rspns.Msg);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const json = JSON.stringify(formData);
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/admin/commission/addcom",
      requestOptions
    );
    const rspns = await req.json();

    setloading(false);
    if (rspns.Status) {
      alert(rspns.Msg);
      updateFormData({
        amount: "",
        comm: "",
        packid: id,
        AdminToken: AdminToken,
      });
      getData();
    } else {
      alert(rspns.Msg);
    }
  };

  const deleteCom = async (packid) => {
    // const confirm = Window.confirm("Are you sure want to delete this ?");
    if (window.confirm("Are you sure want to delete this ?") === true) {
      setloading(true);
      const json = JSON.stringify({ AdminToken, packid });
      const requestOptions = {
        method: "POST",
        body: json,
      };
      const req = await fetch(
        "https://limba.in/Backend/admin/commission/deletecom",
        requestOptions
      );
      const rspns = await req.json();

      setloading(false);
      if (rspns.Status) {
        alert(rspns.Msg);
        getData();
      } else {
        alert(rspns.Msg);
      }
    }
  };
  return (
    <>
      <div className="main-body">
        <div className="card">
          <div className="card-header">
            <h5> Add Package</h5>
          </div>
          <div className="card-block container">
            <div className="row">
              <div className="col-lg-12">
                <form method="post" onSubmit={handleSubmit}>
                  <div className="form-group row mt-3">
                    <div className="form-group col-sm-6">
                      <input
                        type="text"
                        value={formData.amount}
                        className="form-control"
                        onChange={handleChange}
                        name="amount"
                        placeholder="Amount"
                      />
                    </div>
                    <div className="form-group col-sm-6">
                      <input
                        type="text"
                        value={formData.comm}
                        className="form-control"
                        onChange={handleChange}
                        name="comm"
                        placeholder="Commission"
                      />
                    </div>
                  </div>
                  <div className="">
                    <button
                      type="submit"
                      disabled={loading}
                      onChange={handleChange}
                      className="btn btn-primary mb-2"
                    >
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="page-body">
          <div className="card">
            <div className="card-header">
              <h5> Package List</h5>
            </div>
            <div className="card-block container">
              <div className="table-responsive dt-responsive">
                <table
                  id="custm-tool-ele"
                  className="table table-striped table-bordered nowrap"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Amount</th>
                      <th>Com</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {packlist.map((data, key) => {
                      return (
                        <tr key={key}>
                          <td>{data.ID}</td>
                          <td>{data.AMOUNT}</td>
                          <td>{data.COMM}</td>
                          <td>
                            <span
                              onClick={() => deleteCom(data.ID)}
                              className="text-danger "
                              style={{ cursor: "pointer" }}
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
