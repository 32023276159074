import React, { useState, useEffect } from "react";
import "./css/style1.css";
import CircularProgress from "@mui/material/CircularProgress";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const MainRech = () => {
  let sno = 1;
  const Token = localStorage.getItem("token");
  const Localavail = localStorage.getItem("avail");
  const rejectRech = "reject";

  const [rechError, setrechError] = useState("");
  const [rech, setrech] = useState([]);
  const [txnid, settxnid] = useState("");
  const [opid, setopid] = useState("");
  const [open, setOpen] = useState(false);
  const [rechSubLoad, setRechSubLoad] = useState(false);
  useEffect(() => {
    if (Token !== "" && Token !== null) {
      getRech();
      if (Localavail === "true") {
        setInterval(() => {
          getRech();
        }, 10000);
      }
    }
  }, []);
  const handleClickOpen = (txnid, opid) => {
    settxnid(txnid);
    setopid(opid);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const rejectRecharge = async (txnid) => {
    if (window.confirm("Are you sure want to reject this")) {
      setRechSubLoad(true);
      const json = JSON.stringify({ Token, txnid, rejectRech });
      console.log(json);
      const requestOptions = {
        method: "POST",
        body: json,
      };
      const req = await fetch(
        "https://limba.in/Backend/retailer/UpdateRech",
        requestOptions
      );
      const rspns = await req.json();
      console.log(rspns);
      if (rspns.Status) {
        alert("Rejected");
        getRech();
        setRechSubLoad(false);
      } else {
        alert(rspns.Msg);
        setRechSubLoad(false);
      }
    }
  };

  const ManualRecharge = (txnid, opid) => {
    handleClickOpen(txnid, opid);
    // document.getElementById("myModal").modal('show');
    // alert("this is manual")
  };
  const handleSubmitRech = async () => {
    setRechSubLoad(true);
    const json = JSON.stringify({ Token, txnid, opid });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/retailer/UpdateRech",
      requestOptions
    );
    const rspns = await req.json();
    console.log(rspns);
    if (rspns.Status) {
      alert("Updated");
      handleClose();
      getRech();
      setRechSubLoad(false);
    } else {
      setRechSubLoad(false);
      alert(rspns.Msg);
    }
  };
  const getRech = async () => {
    const json = JSON.stringify({ Token });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/retailer/getRech",
      requestOptions
    );
    const rspns = await req.json();
    if (rspns.Status) {
      if (rspns.Result !== null) {
        setrech(rspns.Result);
      }
    } else {
      setrech([]);
      setrechError(rspns.Msg);
    }
  };

  return (
    <div className="container">
      <div className="innercontent">
        <div className="" id="ajaxgrid">
          <div className="table table-responsive margtop">
            {/* <div  className="dataTables_lengdiv pull-right paginat" id="DataTables_Table_0_lengdiv">
            <label>
              <select id="pagesz"  className="form-condivol input-sm selectPageSize">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </label>
          </div> */}
            <div className="table table-bordered table-sdiviped col-sm-12 ktable">
              <div className="row hide-xs">
                <div className="col-sm-1 col-6">
                  <b>Sn</b>
                </div>
                <div className="col-sm-1 col-6">
                  <b>Txn Id</b>
                </div>
                <div className="col-sm-2 col-6">
                  <b>Date</b>
                </div>
                <div className="col-sm-1 col-6">
                  <b>Service</b>
                </div>
                <div className="col-sm-1 col-6">
                  <b>Operator</b>
                </div>
                <div className="col-sm-2 col-6">
                  <b>Customer</b>
                </div>
                <div className="col-sm-1 col-6">
                  <b>Amount</b>
                </div>
                <div className="col-sm-2 col-6">
                  <b>Status</b>
                </div>
                <div className="col-sm-1 col-6 text-center">
                  <b>Action</b>
                </div>
              </div>
              {rech.length !== 0 ? (
                rech.map((array) => (
                  <form method="get" key={array.row.ID}>
                    <div className="row mb-2 border">
                      <div className="col-sm-1 col-6 hide-xs border">
                        {sno++}
                      </div>
                      <div className="col-sm-1 col-12 border text-center bg-light text-primary ">
                        <b>Txn Id: {array.row.ID}</b>
                      </div>
                      <div className="col-sm-2 col-6 border">
                        {array.row.DATE} {array.row.TIME}
                      </div>
                      <div className="col-sm-1 col-6 border">
                        {array.row.SERVICETYPE}
                      </div>
                      <div className="col-sm-1 col-6 border">
                        {array.row.OPERATOR}
                      </div>
                      <div className="col-sm-2 col-6 border">
                        {array.row.NUMBER}
                      </div>
                      <div className="col-sm-1 col-6 border">
                        {array.row.AMOUNT}
                      </div>
                      <div
                        className={
                          array.row.RCSTATUS === "PENDING"
                            ? "col-sm-1 col-6 border text-warning"
                            : array.row.RCSTATUS === "SUCCESS"
                            ? "col-sm-1 col-6 border text-success"
                            : "col-sm-1 col-6 border text-danger"
                        }
                      >
                        {array.row.RCSTATUS} {array.time}{" "}
                      </div>

                      {array.row.TIMESTATUS === "WAIT" ? (
                        array.row.RCSTATUS === "PENDING" ? (
                          <div className="col-sm-2 col-12 row border  text-center m-0 p-0">
                            <button
                              type="button"
                              disabled={rechSubLoad}
                              className="btn btn-success col-5"
                              onClick={() =>
                                ManualRecharge(array.row.ID, array.txnid)
                              }
                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              {" "}
                              {rechSubLoad ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                "Submit"
                              )}
                            </button>
                            &nbsp;
                            <button
                              type="button"
                              disabled={rechSubLoad}
                              className="btn btn-danger col-5"
                              onClick={() => rejectRecharge(array.row.ID)}
                            >
                              {rechSubLoad ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                "Reject"
                              )}
                            </button>
                          </div>
                        ) : (
                          <div className="col-sm-2 col-12 border  text-center">
                            Completed
                          </div>
                        )
                      ) : (
                        ""
                      )}
                      <input
                        type="hidden"
                        name="rech_id"
                        value={array.row.ID}
                      />
                    </div>
                  </form>
                ))
              ) : (
                <form method="get">
                  <div className="row mb-2 border">
                    <div className="col-sm-12 col-12 hide-xs border">
                      <p className="text-center" id="errmsg">
                        {rechError}
                      </p>
                    </div>
                  </div>
                </form>
              )}
              {/* <form method="get">
              <div className="row mb-2 border">
                <div className="col-sm-1 col-6 hide-xs border">1</div>
                <div className="col-sm-1 col-12 border text-center bg-light text-primary "><b>Txn
                  Id: 241393</b></div>
                <div className="col-sm-2 col-6 border">2022-01-28 10:48:09 AM</div>
                <div className="col-sm-1 col-6 border">Prepaid</div>
                <div className="col-sm-1 col-6 border">Airtel</div>
                <div className="col-sm-2 col-6 border">9937618321</div>
                <div className="col-sm-1 col-6 border">99</div>
                <div className="col-sm-1 col-6 border text-danger">
                  FAILED                                                            </div>
                <input type="hidden" name="rech_id" value="241393" />
              </div>
            </form> */}
              <div className="footer" style={{ marginLeft: "68%" }}></div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div id="editable_info" className="dataTables_info"></div>
            </div>
            <div className="col-sm-6"></div>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Submit Recharge</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can't submit recharge after time goes away.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="txnid"
            label="Txn Id"
            value={txnid}
            disabled={true}
            readOnly
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="opid"
            value={opid}
            onChange={(e) => {
              setopid(e.target.value);
            }}
            label="Operator Id"
            type="text"
            disabled={rechSubLoad}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={rechSubLoad}>
            Cancel
          </Button>
          <Button onClick={handleSubmitRech} disabled={rechSubLoad}>
            {rechSubLoad ? <CircularProgress color="success" /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MainRech;
