import React, { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import StateContext from "../../context/StateContext";
import Navbar from './Navbar';
import RechForm from './RechForm';
import ReactVirtualizedTable  from './MainRech';
// import { BaseAlert } from './Alert';

export const Retailer = (props) => {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const { appState, setappState } = state;
  
  useEffect(() => {
    if (localStorage.getItem("token") === "" || localStorage.getItem("token") === null) {
      navigate("/");
    }
    else {
      setappState({
        "login": true,
        "authToken": localStorage.getItem("token")
      })
    }
  }, []);
  const logout = () => {
    localStorage.setItem("token", "");
    navigate("/");
  }
  return (<>
          <Navbar logout={logout}/>
          {/* <BaseAlert type="success" msg="Hello"/> */}
          <RechForm/>
          <ReactVirtualizedTable/>
          </>);
};
