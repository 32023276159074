import { React, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://Limba.in">
        Limba.in
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const MultiLogin = (props) => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setloading] = useState(false);

  // console.log(appState);
  useEffect(() => {
    if (
      localStorage.getItem("MultiToken") !== "" &&
      localStorage.getItem("MultiToken") !== null
    ) {
      navigate("/multi/Home");
    }
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setloading(true);
    const data = new FormData(event.currentTarget);
    const Mobile = data.get("Mobile");
    const Pass = data.get("password");
    const json = JSON.stringify({ Mobile: Mobile, Pass: Pass });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/multi/login/login",
      requestOptions
    );
    const rspns = await req.json();
    if (rspns.Status) {
      localStorage.setItem("MultiToken", rspns.Token);
      localStorage.setItem("AdminToken", "");
      localStorage.setItem("token", "");
      navigate("/multi/Home");
    } else {
      setloading(false);
      alert(rspns.Msg);
    }
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Multi Provider Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="Mobile"
              label="Mobile Or Email"
              name="Mobile"
              autoComplete="mobile"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={passwordShown ? "text" : "password"}
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              id="showpass"
              name="showpass"
              control={
                <Checkbox
                  value="showpass"
                  onClick={togglePassword}
                  color="primary"
                />
              }
              label="Show Password"
            />
            <Button
              type="submit"
              fullWidth
              disabled={loading}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {!loading ? "Sign In" : <CircularProgress />}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  to="/forgotPassword"
                  component={RouterLink}
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/Register" component={RouterLink} variant="body2">
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default MultiLogin;
