import React from "react";
import MuiCard from "../components/MuiCard";

import { useEffect, useState } from "react";

import { Loading } from "./components/Loading";
import TodayReportTable from "./components/TodayReportTable";

export const MultiDashboard = (props) => {
  const MultiToken = localStorage.getItem("MultiToken");
  const [rows, setrows] = useState([]);
  const [loading, setloading] = useState(true);
  const [refresh, setrefresh] = useState(false);
  const [Operators, setOperators] = useState([]);
  const [allService, setallService] = useState([]);
  const [details, setdetails] = useState({
    success: 0,
    pending: 0,
    failed: 0,
    refunded: 0,
    refundedam: 0,
    successam: 0,
    failedam: 0,
    pendingam: 0,
  });
  const [formData, setformData] = useState({
    service: "",
    operator: "",
    amount: "",
    number: "",
    MultiToken: MultiToken,
  });
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    // setpage(page + 1);
    setloading(true);
    const json = JSON.stringify({ MultiToken });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/multi/recharge/getHomeData",
      requestOptions
    );
    const rspns = await req.json();
    console.log(rspns);
    setloading(false);

    if (rspns.Status) {
      if (rspns.Result !== null) {
        setdetails({
          success: Number(rspns.success),
          pending: Number(rspns.pending),
          failed: Number(rspns.failed),
          refunded: Number(rspns.refunded),
          refundedam: Number(rspns.refundedam),
          successam: Number(rspns.successam),
          failedam: Number(rspns.failedam),
          pendingam: Number(rspns.pendingam),
        });
        setrows(rspns.Result);
      }
      setallService(rspns.services);
    } else {
      setrows([]);
    }
  };
  const getOp = async (e) => {
    handleChange(e);
    setloading(true);
    const json = JSON.stringify({ MultiToken, service: e.target.value });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/multi/multiinfo/getServices",
      requestOptions
    );
    const rspns = await req.json();
    //   console.log(rspns);
    if (rspns.Status) {
      setloading(false);
      if (rspns.Result !== "" && rspns.Result !== null) {
        setOperators(rspns.Result);
      }
    } else {
      setloading(false);
      alert(rspns.Msg);
    }
  };

  const handleChange = (e) => {
    setformData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const json = JSON.stringify(formData);
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/multi/recharge/addRech",
      requestOptions
    );
    const rspns = await req.json();
    //   console.log(rspns);
    if (rspns.Status) {
      alert(rspns.Msg);
      setformData({
        service: "",
        operator: "",
        amount: "",
        number: "",
        MultiToken: MultiToken,
      });
      setloading(false);
      getData();
    } else {
      setloading(false);
      alert(rspns.Msg);
    }
  };

  if (refresh) {
    setloading(true);
    setrows([]);
    getData();
    setrefresh(false);
  }

  return (
    <>
      <Loading loading={loading} />
      <div className="container">
        <div className="row">
          <div className="col-md-3 my-1">
            <MuiCard maintext="Success" text={details.success} icon="bx:user" />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard maintext="Pending" text={details.pending} icon="bx:user" />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="Failed"
              text={details.failed}
              icon="bx:user-check"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="Refunded"
              text={details.refunded}
              icon="bx:user-check"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="S. Amount"
              text={details.successam}
              icon="bx:user"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="P. Amount"
              text={details.pendingam}
              icon="bx:user-check"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="F. Amount"
              text={details.failedam}
              icon="bx:user"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="R. Amount"
              text={details.refundedam}
              icon="bx:user"
            />
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-header">
            <h5>Add Entery</h5>
          </div>

          <div className="card-block container">
            <form action="" method="post" onSubmit={handleSubmit}>
              <div className="form-group row ">
                <div className="col-sm-3">
                  <label htmlFor="">Select Service</label>
                  <select
                    name="service"
                    className="form-control"
                    onChange={(e) => getOp(e)}
                    required
                  >
                    <option value="">Service</option>
                    {allService.map((ser, key) => {
                      return (
                        <option
                          key={key}
                          selected={formData.service === ser.SERVICENAME}
                          value={ser.SERVICENAME}
                        >
                          {ser.SERVICENAME}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-sm-3">
                  <label htmlFor="">Select Opertaor</label>
                  <select
                    name="operator"
                    className="form-control"
                    onChange={handleChange}
                    id="op_list"
                    required
                  >
                    <option value="">Operator</option>
                    {Operators.map((ser, key) => {
                      return (
                        <option
                          key={key}
                          selected={formData.operator === ser.PRODUCTNAME}
                          value={ser.PRODUCTNAME}
                        >
                          {ser.PRODUCTNAME}
                        </option>
                      );
                    })}
                  </select>
                  <span className="messages"></span>
                </div>

                <div className="col-sm-3">
                  <label htmlFor="">Enter Number</label>
                  <input
                    type="number"
                    className="form-control"
                    value={formData.number}
                    name="number"
                    onChange={handleChange}
                    placeholder="Enter Number"
                    required
                  />
                  <span className="messages"></span>
                </div>
                <div className="col-sm-3">
                  <label htmlFor="">Enter Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    value={formData.amount}
                    name="amount"
                    onChange={handleChange}
                    placeholder="Enter Number"
                    required
                  />
                  <span className="messages"></span>
                </div>
              </div>
              <div className="form-group row text-center">
                <div className="col-sm-12">
                  <button
                    type="submit"
                    id="sub_btn"
                    name="submit"
                    className="btn btn-primary m-b-0"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <TodayReportTable
          data={rows}
          setloading={setloading}
          refresh={setrefresh}
        />
      </div>
    </>
  );
};
