import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "../css/style1.css";
const Token = localStorage.getItem("token");

const ReportTable = (props) => {
  let sno = 1;
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [report, setreport] = useState([]);
  const {
    fdate,
    tdate,
    updateFilter,
    setupdateFilter,
    type,
    setsaleData,
    saleData,
  } = props;

  useEffect(() => {
    // setInterval(() => {
    if (Token !== "" && Token !== null) {
      getReport();
    }
    // }, 3000);
  }, []);

  const getReport = async () => {
    const json = JSON.stringify({ Token, page, fdate, tdate, type });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/retailer/getReport",
      requestOptions
    );
    const rspns = await req.json();
    // console.log(rspns);
    if (rspns.Status) {
      if (rspns.Result !== null) {
        console.log(rspns);
        setreport(rspns.Result);
        setTotalResults(rspns.TotalResult);
        setsaleData({
          success: rspns.success,
          successam: rspns.successam,
          pending: rspns.pending,
          pendingam: rspns.pendingam,
          failed: rspns.failed,
          failedam: rspns.failedam,
        });
      }
    } else {
      // alert(rspns.Msg)
    }
  };

  const getmoreReport = async () => {
    setPage(page + 1);
    const nxtpage = page + 1;
    const json = JSON.stringify({ Token, page: nxtpage, fdate, tdate, type });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/retailer/getReport",
      requestOptions
    );
    const rspns = await req.json();
    if (rspns.Status) {
      if (rspns.Result !== null) {
        setreport(report.concat(rspns.Result));
        setsaleData({
          success: Number(saleData.success) + Number(rspns.success),
          successam: Number(saleData.successam) + Number(rspns.successam),
          pending: Number(saleData.pending) + Number(rspns.pending),
          pendingam: Number(saleData.pendingam) + Number(rspns.pendingam),
          failed: Number(saleData.failed) + Number(rspns.failed),
          failedam: Number(saleData.failedam) + Number(rspns.failedam),
        });
        setTotalResults(rspns.TotalResult);
      }
    } else {
      alert(rspns.Msg);
    }
  };

  if (updateFilter) {
    if (Token !== "" && Token !== null) {
      getReport();
      setupdateFilter(false);
    }
  }

  return (
    <div className="container">
      <div className="innercontent">
        <div className="table table-responsive margtop">
          <div className="table table-bordered table-sdiviped col-sm-12 ktable">
            <div className="row hide-xs">
              <div className="col-sm-1 col-6">
                <b>Sn</b>
              </div>
              <div className="col-sm-1 col-6">
                <b>Txn Id</b>
              </div>
              <div className="col-sm-2 col-6">
                <b>Date</b>
              </div>
              <div className="col-sm-1 col-6">
                <b>Service</b>
              </div>
              <div className="col-sm-1 col-6">
                <b>Operator</b>
              </div>
              <div className="col-sm-2 col-6">
                <b>Customer</b>
              </div>
              <div className="col-sm-1 col-6">
                <b>Amount</b>
              </div>
              <div className="col-sm-2 col-6">
                <b>Status</b>
              </div>
              <div className="col-sm-1 col-6 text-center">
                <b>Action</b>
              </div>
            </div>
            <div id="scrollableDiv" style={{ height: 500, overflow: "auto" }}>
              <InfiniteScroll
                dataLength={report.length}
                next={getmoreReport}
                hasMore={report.length !== totalResults}
                scrollableTarget="scrollableDiv"
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b> <br />
                  </p>
                }
                loader={<h4>Loading...</h4>}
              >
                {report.map((array) => (
                  <form method="get" key={array.row.ID}>
                    <div className="row mb-2 border">
                      <div className="col-sm-1 col-6 hide-xs border">
                        {sno++}
                      </div>
                      <div className="col-sm-1 col-12 border text-center bg-light text-primary ">
                        <b>Txn Id: {array.row.ID}</b>
                      </div>
                      <div className="col-sm-2 col-6 border">
                        {array.row.DATE} {array.row.TIME}
                      </div>
                      <div className="col-sm-1 col-6 border">
                        {array.row.SERVICETYPE}
                      </div>
                      <div className="col-sm-1 col-6 border">
                        {array.row.OPERATOR}
                      </div>
                      <div className="col-sm-2 col-6 border">
                        {array.row.NUMBER}
                      </div>
                      <div className="col-sm-1 col-6 border">
                        {array.row.AMOUNT}
                      </div>
                      <div
                        className={
                          array.row.RCSTATUS === "PENDING"
                            ? "col-sm-1 col-6 border text-warning"
                            : array.row.RCSTATUS === "SUCCESS"
                            ? "col-sm-1 col-6 border text-success"
                            : "col-sm-1 col-6 border text-danger"
                        }
                      >
                        {array.row.RCSTATUS}{" "}
                      </div>
                      <div className="col-sm-2 col-12 border  text-center">
                        Not Avail
                      </div>
                      <input
                        type="hidden"
                        name="report_id"
                        value={array.row.ID}
                      />
                    </div>
                  </form>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportTable;
