import React from "react";
import MuiCard from "../components/MuiCard";

import { useEffect, useState } from "react";

import MultiTable from "./components/MultiTable";
import { Loading } from "./components/Loading";

export const MultiList = (props) => {
  const AdminToken = localStorage.getItem("AdminToken");
  const [page, setpage] = useState(0);
  const [rows, setrows] = useState([]);
  const [loading, setloading] = useState(true);
  const [refresh, setrefresh] = useState(false);

  const [searchval, setsearchval] = useState("");
  const [details, setdetails] = useState({
    totaluser: 0,
    active: 0,
    inactive: 0,
    totalBal: 0,
  });

  const [addForm, updateaddForm] = useState({
    name: "",
    mobile: "",
    email: "",
    pass: "",
    address: "",
    city: "",
    AdminToken: AdminToken,
  });
  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    updateaddForm({
      ...addForm,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  const getData = async () => {
    setloading(true);
    const json = JSON.stringify({ AdminToken, page, searchval });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/admin/Multi/getMultiData",
      requestOptions
    );
    const rspns = await req.json();
    if (rspns.Status) {
      setloading(false);
      setdetails({
        totaluser: rspns.TotalResult,
        active: rspns.ActiveUser,
        inactive: rspns.InActiveUser,
        totalBal: rspns.TotalBal,
      });
      if (rspns.Result !== null) {
        setrows(rspns.Result);
      }
    } else {
      setloading(false);
      setrows([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const json = JSON.stringify(addForm);
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://limba.in/Backend/admin/Multi/addMulti",
      requestOptions
    );
    const rspns = await req.json();
    setloading(false);
    if (rspns.Status) {
      updateaddForm({
        name: "",
        mobile: "",
        email: "",
        pass: "",
        address: "",
        city: "",
        AdminToken: AdminToken,
      });
      alert(rspns.Msg);
      getData();
    } else {
      alert(rspns.Msg);
    }
  };

  if (refresh) {
    getData();
    setrefresh(false);
  }

  return (
    <>
      <Loading loading={loading} />
      <div className="container">
        <div className="row">
          <div className="col-md-3 my-1">
            <MuiCard maintext="Total" text={details.totaluser} icon="bx:user" />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="Active"
              text={details.active}
              icon="bx:user-check"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="In-Active"
              text={details.inactive}
              icon="bx:user-x"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="Total Bal."
              text={details.totalBal}
              icon="dashicons:money-alt"
            />
          </div>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          Add Multi
        </button>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <form action="" onSubmit={handleSubmit}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Multi
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="form-group col-6">
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={addForm.name}
                        placeholder="Name"
                        name="name"
                      />
                    </div>
                    <div className="form-group col-6">
                      <input
                        type="number"
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={addForm.mobile}
                        placeholder="Mobile Number"
                        name="mobile"
                      />
                    </div>
                    <div className="form-group col-6">
                      <input
                        type="email"
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={addForm.email}
                        placeholder="Email ID"
                        name="email"
                      />
                    </div>
                    <div className="form-group col-6">
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={addForm.pass}
                        placeholder="Secret Password"
                        name="pass"
                      />
                    </div>
                    <div className="form-group col-6">
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        value={addForm.address}
                        placeholder="Address"
                        name="address"
                      />
                    </div>
                    <div className="form-group col-6">
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        value={addForm.city}
                        placeholder="City"
                        name="city"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn btn-primary"
                  >
                    Add User
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row m-2">
          <input
            type="text"
            className="form-control col-3 mb-2"
            placeholder="Search by Name or Mobile"
            value={searchval}
            onChange={(e) => setsearchval(e.target.value)}
          />
          <button className="btn btn-primary" onClick={getData}>
            Update Filter
          </button>
        </div>
        <MultiTable rows={rows} setloading={setloading} refresh={setrefresh} />
      </div>
    </>
  );
};
